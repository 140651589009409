/* eslint-disable import/no-anonymous-default-export */
export default {

  // home banner
  BannerHead: "Opplev luksus på norske veier med eksklusive biler",
  BannerSbHead:
    "Ønsker du å bestille en bil for transport til og fra flyplassen? Velg det beste fra vår flåte for en enestående opplevelse.",
  Pbrand: "Våre biltyper",
  //   choose us
  chosseUs: "Hvofor oss?",
  chooseHead: "Rent a Vehicle",
  chooseHead1: "Gjennomført kvalitet",
  choosesbHead1:
    "Vi tilbyr et eksklusivt utvalg luksusbiler som Mercedes, Audi, Tesla, Nio og BMW. Opplev komfort, sportslighet og topp moderne teknologi i biler som alltid er perfekt vedlikeholdt.",
  chooseHead2: "Trygghet på reisen",
  choosesbHead2:"Vårt dedikerte team sørger for at du kan nyte turen uten bekymringer, takket være deres omfattende opplæring og fokus på detaljer. Vi setter alltid sikkerhet først og tar alle nødvendige forholdsregler for å gi deg en bekymringsfri og behagelig opplevelse fra start til slutt.Vårt dedikerte team sørger for at du kan nyte turen uten bekymringer, takket være deres omfattende opplæring og fokus på detaljer. Vi setter alltid sikkerhet først og tar alle nødvendige forholdsregler for å gi deg en bekymringsfri og behagelig opplevelse fra start til slutt.",
  chooseHead3: "Pålitelighet",
  choosesbHead3:"Vi sørger for at bilene våre er tilgjengelige til enhver tid og leveres til avtalt tid, slik at du kan stole på oss for pålitelig og punktlig service.",

  //   about uss
  aboutUs: "Om oss",
  homeAboutHead: "Exclusive Drive",
  homeAboutsbHead1:
    "Exclusive Drive AS – ditt ledende valg for en behagelig, pålitelig og miljøvennlig reiseopplevelse. Med flere års erfaring innen persontransport har vi forpliktet oss til å levere førsteklasses taxi tjenester med fokus på bærekraft. Vi skiller oss ut ved å være et miljøvennlig taxi selskap, da vi utelukkende benytter elektriske biler. Dette gjør ikke bare reisen mer behagelig, men reduserer også vår påvirkning på miljøet.",
  homeAboutsbHead2:
    "Vi har et sterkt fokus på kundetilfredshet, og vårt mål er å gjøre reisen din så problemfri som mulig. Uansett om du er forretningsreisende, turist eller lokal innbygger, kan du stole på Exclusive Drive AS for å tilby deg enestående taxi tjenester. Velg oss for dine transportbehov, og opplev en reise som kombinerer komfort, pålitelighet og miljøvennlighet. Hos Exclusive Drive AS er vi klare til å ta deg med på veien til en bærekraftig og behagelig reiseopplevelse.",
  aboutBtn: "Bestill nå",
  // get started
  startedHead: "Opplev luksuriøs transport med Exclusive Drive.",
  startedSbHead:
    "Hos Exclusive Drive prioriterer vi din sikkerhet. Våre dedikerte sjåfører gjennomgår grundig opplæring for å sikre at dine reiser er trygge, komfortable og profesjonelle.",
  startedBtn: "Kom i gang",
  //   footer
  footerHead1:"Links",
  footerHead2:"Kontakt oss",
  footerHead3:"Følg oss",
  footerLink2: "FAQs",
  footerPhone: "+47 921 34 830",
  footerMail: "kundeservice@exclusivedrive.no",
  footerLocation: "Ryenstubben 3 Oslo 0679",
  footerCopyRight: "Copyright Exclusive Drive, LLC2024",

  // contact us

  contactUs: "Kontakt oss",
  contactHead: "Opplev luksuriøs transport med Exclusive Drive.",
  formName: "Navn",
  formLname: "Etter Navn",
  formEmail: "E-post",
  fromAddres: "Address",
  fromMsg: "Melding",
  contactFormbtn: "Send Melding",

  //   faq
  faqHead: "Vanlig spørsmål",

  // set location

  Plocation: "Pickup location",
  Dlocation: "Drop-off location",
  Departure: "Avreise",
  locationsetbtn: "Priser",
  addressRequired: "Address er påbudt",
  DepartureDate: "Avreise dato er påbudt",
  DepartureTime: "Avreise tid er påbudt",

  // book trip

  Scar: "Velg bil",
  payement: "Betaling",
  tripBtn: "Bestill",
  saveBtn: "Lagre",
  trmHead: "Bestill",
  mailRequire: "E-post er påbudt",
  fNameREquire: "Navn er påbudt",
  PhoneRequire: "Ugyldig format. (auth/invalid-phone-number).",
  submit: "Submit",

  // form place holder
  form: "Fra",
  to: "Til",
  dataPlace: "MM/DD/YYYY",
  timePlace: "hh:mm aa",
  carPlace: "Velg bil",
  payPlcae: "kr52732",
  FName: "Full Navn",
  mailPlace: "E-post",
};
