/* eslint-disable import/no-anonymous-default-export */
export default {

  // home banner
  BannerHead: "Experience Luxury on Norway Roads with Exclusive Cars",
  BannerSbHead:
    "Looking to book a car for camping, city cruising, or airport transfers? Choose the best from our fleet for an exceptional experience.",
  Pbrand: "Popular Brands We Have",
  //   choose us
  chosseUs: "Why Choose Us",
  chooseHead: "Rent a Vehicle",
  chooseHead1: "Thorough quality",
  choosesbHead1:
    "We offer an exclusive selection of luxury cars, including Mercedes, Audi, Tesla, Nio, and BMW. Experience comfort, sportiness, and cutting-edge technology in cars that are always perfectly maintained.",
  chooseHead2: "Safety on Your Journey",
  choosesbHead2:"Our dedicated team ensures you can enjoy your trip with complete peace of mind, thanks to their extensive training and attention to detail. We always prioritize safety and take every necessary precaution to provide you with a worry-free and comfortable experience from start to finish.",
  chooseHead3: "Reliability",
  choosesbHead3:"We ensure that our cars are available at all times and delivered on schedule, so you can trust us for reliable and punctual service",

  //   about uss
  aboutUs: "About us",
  homeAboutHead: "Exclusive Drive",
  homeAboutsbHead1:
    "Exclusive Cars brand is one of the most reliable motor oil manufacturers, which is engaged in the production of high quality products with a history of more than decades in the industry. In order to get more information about other aspects and products of the brand.",
  homeAboutsbHead2:
    "The meaning of production in Exclusive Cars is the creation, development, and the path to progress, and the starting point to achieve the goals that we all have the Petroforce brand, with over 20 years of experience in the oil and petrochemical industry, we officially started our activities in the field of design, engineering, construction of refinery equipment, and the production of various motor and industrial lubricants in the year 1390 (2011)",
  aboutBtn: "Book now",
  // get started
  startedHead: "Experience luxury transportation tailored with Exclusive Cars.",
  startedSbHead:
    "Nestled in the heart of comfort and aesthetic appeal, this exquisite car is a harmonious blend of warmth and style. Crafted with meticulous attention to detail, its frame is fashioned from solid engine, providing both durability and a timeless charm.",
  startedBtn: "Get started",
  //   footer
  footerHead1:"Links",
  footerHead2:"Get in Touch",
  footerHead3:"Follow Us",
  footerLink2: "FAQs",
  footerPhone: "+47 921 34 830",
  footerMail: "kundeservice@exclusivedrive.no",
  footerLocation: "Ryenstubben 3 Oslo 0679",
  footerCopyRight: "Copyright Exclusive Drive, LLC2024",

  // contact us

  contactUs: "Contact Us",
  contactHead: "Experience luxury transportation tailored with Exclusive Cars.",
  formName: "First Name",
  formLname: "Last Name",
  formEmail: "Email",
  fromAddres: "Address",
  fromMsg: "Message",
  contactFormbtn: "Send Message",

  //   faq
  faqHead: "Frequently Asked Questions",

  // set location

  Plocation: "Pickup location",
  Dlocation: "Drop-off location",
  Departure: "Departure",
  locationsetbtn: "See Price",
  addressRequired: "Address is required",
  DepartureDate: "Departure date is required",
  DepartureTime: "Departure time is required",

  // book trip

  Scar: "Select Car",
  payement: "Payment",
  tripBtn: "Book Trip",
  saveBtn: "Save",
  trmHead: "Book Trip",
  mailRequire: "Email is required",
  fNameREquire: "First Name is required",
  PhoneRequire: "Invalid format. (auth/invalid-phone-number).",
  submit: "Submit",

  // form place holder
  form: "Form",
  to: "To",
  dataPlace: "MM/DD/YYYY",
  timePlace: "hh:mm aa",
  carPlace: "Select Car",
  payPlcae: "KR52732",
  FName: "Full Name",
  mailPlace: "Enter email",
};
